export default {
  data() {
    return {
      itemsPerPage: 20,
    }
  },
  computed: {
    pageOptions() {
      const page_options = [this.arrayList.length, -1];
      if (this.arrayList.length > 20) page_options.splice(0, 1, 20);
      if (this.arrayList.length > 50) page_options.splice(1, 0, 50);
      if (this.arrayList.length > 100) page_options.splice(2, 0, 100);
      return {
        "items-per-page-options": page_options,
      };
    },
  },
}